import React from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const Monthlies = ({monthReport}) => {
    
      const data = [
        { month: "January", total: monthReport[0] },
        { month: "February", total: monthReport[1] },
        { month: "March", total: monthReport[2] },
        { month: "April", total: monthReport[3]},
        { month: "May", total: monthReport[4]},
        { month: "June", total: monthReport[5] },
        { month: "July", total: monthReport[6] },
        { month: "August", total: monthReport[7] },
        { month: "September", total: monthReport[8]},
        { month: "October", total: monthReport[9] },
        { month: "November", total: monthReport[10] },
        { month: "December", total: monthReport[11] },
  ];
      const chartOptions = {
        title: {
          text: '',
        },
        xAxis: {
          categories: data.map((item) => item.month),
          title: {
            text: 'Months',
          },
        },
        yAxis: {
          title: {
            text: 'Total',
          },
        },
        series: [
          {
            name: 'Total',
            data: data.map((item) => item.total),
          },
        ],
        chart: {
          type: 'line',
        },
      };
  return (
    <div className="card Cost-Tracking-chart"  >
      <div className="border-bottom border-black  d-md-block border-bottom-md" >
            {/* <h4 style={{ color: "#2c2c2c",marginLeft:"18px" }} className="pt-2">
            Monthly Invoice Totals
            </h4> */}
            <h6 className="mb-0 p-2 "> Monthly Invoice Totals</h6>
          </div>
         <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  )
}

export default Monthlies
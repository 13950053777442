import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const CostTracking = ({chartData}) => {
  const {POICAmount,POJCAmount,POOCAmount, POTCAmount,POUCAmount  }=chartData
 
 
  const data = [
    { title: "Job Cost", total: POJCAmount },
    { title: "Truck Cost", total: POTCAmount },
    { title: "Incident Cost", total: POICAmount },
    { title: "Uniform Cost", total: POUCAmount },
    { title: "Office Cost", total: POOCAmount },
  ];

  
  
  const chartOptions = {
    chart: {
      type: "pie",
    },
    title: {
     
      text: '',
 
    },
    tooltip: {
      pointFormat:
        "{series.name}: <b>{point.y}</b> <br/> Percentage: <b>{point.percentage:.1f}</b> %",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        size: "55%",
        dataLabels: {
          enabled: true,
          formatter: function () {
            return `<b>${this.point.name}</b>: ${this.y}`;
          },
        },
      },
    },
    series: [
      {
        name: "Cost Share",
        colorByPoint: true,
        data: data.map((item) => ({
          name: item.title,
          y: item.total,
        })),
      },
    ],
  };
  
  return (
    <div className="card Cost-Tracking-chart"  >
    
    <div className="border-bottom border-black  d-md-block border-bottom-md"  >
            {/* <h4 style={{ color: "#2c2c2c",marginLeft:"18px" }} className="pt-2">
            Cost Tracking
            </h4> */}
              <h6 className="mb-0 p-2 ">Cost Tracking</h6>
          </div>
    
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default CostTracking;

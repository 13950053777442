import React, { useEffect, useState, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  TextField,
  TablePagination,
  Checkbox,
  Button,
  Grid,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Cookies from "js-cookie";
import { Delete, Create } from "@mui/icons-material";
import axios from "axios";
import { DataContext } from "../../context/AppData";
import AddButton from "../Reusable/AddButton";
import { baseUrl } from "../../apiConfig";
import TitleBar from "../TitleBar";
import useGetApi from "../Hooks/useGetApi";
import {formatDateToCustomString} from "../Reusable/Utils";
import { formatTimeToCustomString } from "../Reusable/Utils";
import { Dialog, DialogTitle, DialogContent, DialogActions,  } from "@mui/material";
import JobForm from "./JobForm";






const JobList = () => {
    const icon = (
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.5096 2.53165H7.41104C5.50437 2.52432 3.94146 4.04415 3.89654 5.9499V15.7701C3.85437 17.7071 5.38979 19.3121 7.32671 19.3552C7.35512 19.3552 7.38262 19.3561 7.41104 19.3552H14.7343C16.6538 19.2773 18.1663 17.6915 18.1525 15.7701V7.36798L13.5096 2.53165Z"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.2688 2.52084V5.18742C13.2688 6.48909 14.3211 7.54417 15.6228 7.54784H18.1482"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.0974 14.0786H8.1474"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.2229 10.6388H8.14655"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
      //dummy data
      const dummyReports = [
        {
            id: 1,
            JobName: "ABC Corp",
            RegionalManager: "John Doe",
            StartedDate: "2025-03-13",
            FertilizerofBag: "56",
            Flatsofcolour: "76",
            MulchQuantity: "87",
            Overseedingofbags: "77",
            PGRgalBPS: "50"
        },
        {
            id: 2,
            JobName: "XYZ Ltd",
            RegionalManager: "Jane Smith",
            StartedDate: "2025-03-12",
            FertilizerofBag: "93",
            Flatsofcolour: "315",
            MulchQuantity: "56",
            Overseedingofbags: "46",
            PGRgalBPS: "70"
        },
        {
            id: 3,
            JobName: "LMN Industries",
            RegionalManager: "Alice Johnson",
            StartedDate: "2025-03-11",
            FertilizerofBag: "59",
            Flatsofcolour: "55",
            MulchQuantity: "87",
            Overseedingofbags: "33",
            PGRgalBPS: "100"
        },
    ];

    const [reports, setReports] = useState(dummyReports); 
        
    // Calculate the sum for each numeric column
const totalFertilizerofBag = reports.reduce((sum, report) => sum + Number(report.FertilizerofBag), 0);
const totalFlatsofcolour = reports.reduce((sum, report) => sum + Number(report.Flatsofcolour), 0);
const totalMulchQuantity = reports.reduce((sum, report) => sum + Number(report.MulchQuantity), 0);
const totalOverseedingofbags = reports.reduce((sum, report) => sum + Number(report.Overseedingofbags), 0);
const totalPGRgalBPS = reports.reduce((sum, report) => sum + Number(report.PGRgalBPS), 0);


    const headers = {
        Authorization: `Bearer ${Cookies.get("token")}`,
      };
      console.log("Token:", Cookies.get("token"));
      const queryParams = new URLSearchParams(window.location.search);
      const customerParam = Number(queryParams.get("CustomerId"));
      const [page, setPage] = useState(0);
      const [rowsPerPage, setRowsPerPage] = useState(100);
      const [statusList, setStatusList] = useState([]);
      const [statusId, setStatusId] = useState(0);
      const [records, setRecords] = useState({});
    
    //   const [reports, setReports] = useState([]);
    
      const [isLoading, setIsLoading] = useState(false);
      const { loggedInUser } = useContext(DataContext);
      const { getData } = useGetApi();
      const navigate = useNavigate();
      const [search, setSearch] = useState("");
      const [openJobPopup, setOpenJobPopup] = useState(false);
    
      // let filteredReports = reports

    //   let filteredReports = reports.filter((report) => 
    //     report.RequestByName?.toLowerCase().includes(search.toLowerCase()) ||
    //     report.DisplayName?.toLowerCase().includes(search.toLowerCase())
    // )
    let filteredReports = reports.filter((report) => 
        report.CustomerName?.toLowerCase().includes(search.toLowerCase()) ||
        report.RegionalManager?.toLowerCase().includes(search.toLowerCase())
    );
    
    
      
    //   const fetchData = async () => {
    //     try {
    //       const response = await axios.get(
    //         `${baseUrl}/api/SafetyReport/GetSafetyReportList`,
    //         { headers }
    //       );
             
    //       console.log("Full API Response:", response); //  full response
    //       console.log("Data Received:", response.data); //  only data
  
    //       if (Array.isArray(response.data) && response.data.length > 0) {
    //           setReports(response.data);
    //         } else {
    //           console.warn("API returned an empty array");
    //       }
    
    //     } catch (error) {
    //         console.error("Fetch error:", error.message);
    //     }
    // };       
    
    //   useEffect(() => {
    //     fetchData();
    //   }, []);

            // delete Api
    //         const deleteReport = async (reportId) => {
    //      try {
    //       console.log("Attempting to delete report with ID:", reportId);
          
    //       if (!reportId) {
    //         console.error("Error: reportId is undefined or null");
    //         return; 
    //       }
    //       const response = await axios.get(
    //         `${baseUrl}/api/SafetyReport/DeleteSafetyReport?id=${reportId}`,
    //         { headers, 
    //         }
    //       );
    //     console.log("Delete API Response:", response);

    //       setReports(reports.filter((report) => report.SafetyFormId !== reportId));
    //       console.log("Report deleted successfully.");

    //      } catch (error) {
    //       console.error("Error deleting report:", error);
    //     }
    //     };
        
    //     const handleRowClick = (reportId) => {
    //       navigate(`/safety-reports/add?id=${reportId}`);
    //   };
    useEffect(() => {
        console.log("JobList component mounted");
    }, []);
    
    
    const handleOpenPopup = () => {
      setOpenJobPopup(true);
    };
  
    const handleClosePopup = () => {
      setOpenJobPopup(false);
    };
      
        
      return (
        <>
        <TitleBar icon={icon} title={"Job List"}></TitleBar>
        <div className="container-fluid">

          {isLoading ? (
            <div className="center-loader">
              <CircularProgress style={{ color: "#789a3d" }} />
            </div>
          ) : (
            <div className="card"> 
                <div className="card-body">
              <div className="row ">
                <div className="col-md-3">
                  <TextField
                    label="Search"
                    variant="standard"
                    size="small"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <div className="col-md-9">
                  <div className="custom-button-container mb-2">
                    {/* {loggedInUser.userRole == "1" && ( */}
                    <AddButton
                    onClick={handleOpenPopup}
                    >
                      Add Job
                    </AddButton>
                  </div>
                </div>
              </div>{" "}
              <TableContainer sx={{ overflowX: "auto" }}>
                <Table>
                  <TableHead className="table-header ">
                    <TableRow className="material-tbl-alignment">
                      {[
                        "#",
                        "Job Name",
                        "Regional Manager",
                        "Start Date",
                        "Fertilizer # of Bag",
                        "# of flats of colour",
                        "Mulch Quantity",
                        "Overseeding # of bags",
                        "PGR 5gal BPS",
                      ].map((headCell) => (
                        <TableCell key={headCell}>{headCell}</TableCell>
                      ))}
                      {loggedInUser.userRole == 1 && (
                        <TableCell align="right">Actions</TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredReports.length <= 0 ? (
                      <TableRow>
                        {" "}
                        <TableCell align="center" colSpan={12}>
                          No Record Found
                        </TableCell>
                      </TableRow>
                    ) : (
                      filteredReports.map((report, index) => (
                        <TableRow key={report.id} hover className="material-tbl-alignment" >
                          <TableCell >
                          {index + 1  || "NA"}
                          </TableCell>
                          
                          <TableCell>{report.JobName}</TableCell>
                          <TableCell>{report.RegionalManager}</TableCell>
                          <TableCell>{report.StartedDate}</TableCell>
                          <TableCell>{report.FertilizerofBag}</TableCell>
                          <TableCell>{report.Flatsofcolour}</TableCell>
                          <TableCell>{report.MulchQuantity}</TableCell>
                          <TableCell>{report.Overseedingofbags}</TableCell>
                          <TableCell>{report.PGRgalBPS}</TableCell>

                          {loggedInUser.userRole == 1 && (
                            <TableCell align="right">
                              {/* Added delete button here */}
                              <span
                                data-bs-toggle="modal"
                                // data-bs-target={`#deleteModal${report.SafetyFormId}`}
                              >
                                <Delete color="error" />
                              </span>

                              {/* Delete confirmation modal */}
                              <div
                                className="modal fade"
                                // id={`deleteModal${report.SafetyFormId}`}
                                tabIndex="-1"
                                aria-labelledby="deleteModalLabel"
                                aria-hidden="true"
                              >
                                <div className="modal-dialog" role="document">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h5 className="modal-title">
                                        Are you sure you want to delete this job?
                                      </h5>
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                      ></button>
                                    </div>
                                    <div className="modal-body text-center">
                                      <button
                                        type="button"
                                        className="btn btn-danger light m-3"
                                        data-bs-dismiss="modal"
                                      >
                                        Close
                                      </button>
                                      <button
                                        className="btn btn-primary m-3"
                                        data-bs-dismiss="modal"
                                        // onClick={() => deleteReport(report.SafetyFormId)}
                                      >
                                        Yes, Delete
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </TableCell>
                          )}
                        </TableRow>
                      ))
                    )}
                    {/* Footer row to display the sum of numeric columns */}
                   <TableRow  className="material-tbl-alignment" >
                   <TableCell colSpan={3} align="left" style={{ fontWeight: "bold" }}>Total:</TableCell>
                   <TableCell></TableCell> {/* Empty cell for spacing */}
                   <TableCell style={{ fontWeight: "bold" }}>{totalFertilizerofBag}</TableCell>
                   <TableCell style={{ fontWeight: "bold" }}>{totalFlatsofcolour}</TableCell>
                   <TableCell style={{ fontWeight: "bold" }}>{totalMulchQuantity}</TableCell>
                   <TableCell style={{ fontWeight: "bold" }}>{totalOverseedingofbags}</TableCell>
                   <TableCell style={{ fontWeight: "bold" }}>{totalPGRgalBPS}</TableCell>
                    {loggedInUser.userRole == 1 && <TableCell></TableCell>} {/* Extra cell for alignment */}
                  </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[100, 200, 300]}
                component="div"
                count={filteredReports.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                onRowsPerPageChange={(event) => {
                  setRowsPerPage(parseInt(event.target.value, 10));
                  setPage(0);
                }}
              />
            </div></div>
          )}
        </div>
        <Dialog open={openJobPopup} onClose={handleClosePopup} fullWidth maxWidth="sm"
      PaperProps={{style: { width: "65%", maxWidth: "65%" } }}>
        <DialogTitle>Job Form</DialogTitle>
        <DialogContent>
        
          <JobForm handleClosePopup={handleClosePopup} fromJobList={true}/>
        </DialogContent>
      
      </Dialog>
        </>
      );
    };
    
export default JobList

import React, { useEffect, useState, useRef, useContext } from "react";
import TextField from "@mui/material/TextField";
import EventPopups from "../Reusable/EventPopups"; 
import LoaderButton from "../Reusable/LoaderButton";
import BackButton from "../Reusable/BackButton";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { baseUrl } from "../../apiConfig";
import Cookies from "js-cookie";
import CustomerAutocomplete from "../Reusable/CustomerAutocomplete";




const JobForm = ({ handleClosePopup, selectedCustomer, fromJobList }) => {
    const [staffData, setStaffData] = useState([]);
    const [disableButton, setDisableButton] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [openJobPopup, setOpenJobPopup] = useState(false);
    const [formData, setFormData] = useState({
        CustomerId: "",
    });
    const token = Cookies.get("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

    const handleAutocompleteChange = (
        fieldName,
        valueProperty,
        event,
        newValue
      ) => {
        const simulatedEvent = {
          target: {
            name: fieldName,
            value: newValue ? newValue[valueProperty] : "",
          },
        };
    
        handleInputChange(simulatedEvent);
      };
      const handleInputChange = (e, newValue) => {
        setDisableButton(false);
        const { name, value } = e.target;
    
        const adjustedValue = [
          "UserId",
          "ServiceLocationId",
          "ContactId",
          "Qty",
          "Rate",
          "EstimateStatusId",
          "RequestedBy",
        ].includes(name)
          ? Number(value)
          : value;
    
        setFormData((prevData) => ({ ...prevData, [name]: adjustedValue }));
      };
      const fetchStaffList = async () => {
        try {
          const response = await axios.get(`${baseUrl}/api/Staff/GetStaffList`, {
            headers,
          });
          setStaffData(response.data);
    
          console.log("staff list iss", response.data);
        } catch (error) {
          console.log("error getting staff list", error);
        }
      };

      useEffect(() => {
        fetchStaffList();
      }, []);
      
      useEffect(() => {
        if (selectedCustomer?.CustomerId) {
          console.log("selectedCustomer in JobForm", selectedCustomer);

          setFormData((prev) => ({
            ...prev,
            CustomerId: selectedCustomer.CustomerId,
            CustomerName: selectedCustomer.CustomerName,
          }));
        }
      }, [selectedCustomer]);
      
      
      

  return (
    
    <>
        <div>
        <div style={{ height: "fit-content" }} className="card ">
                <div className="itemtitleBar d-flex justify-content-between">
                  <h4 className="modal-title w-50" id="#gridSystemModal">
                    Job Form
                  </h4>
                 
                </div>
               
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <div className="row"></div>
                        <div className="row">
                          <div className="col-md-4 mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Customer Name{" "}
                              <span className="text-danger">*</span>
                            </label>
                                  
                                  {
                                fromJobList ? (
                                 <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                 placeholder="Customer Name"
                                  value={formData.CustomerName || ""}
                                   />
                                   ) : (
                                    Number(selectedCustomer?.CustomerId) === 0 ? (
                                    <CustomerAutocomplete
                                     formData={formData}
                                    setFormData={setFormData}
                                    submitClicked={submitClicked}
                                      />
                                       ) : (
                                    <TextField
                                    fullWidth
                                     variant="outlined"
                                      size="small"
                                     placeholder="Customer Name"
                                     value={formData.CustomerName || ""}
                                      />
                                        )
                                        )
                                       }
                          </div>                      
                            <div className="col-md-4 mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Regional Manager
                                <span className="text-danger">*</span>
                              </label>
                              <Autocomplete
                        id="staff-autocomplete"
                        size="small"
                        options={staffData.filter(
                          (staff) =>
                            staff.Role === "Regional Manager" ||
                            staff.UserId === 1593 ||
                            staff.UserId === 3252 ||
                            staff.UserId === 6146 ||
                            staff.UserId === 9517
                        )}
                        getOptionLabel={(option) =>
                          option.FirstName + " " + option.LastName || ""
                        }
                        value={
                          staffData.find(
                            (staff) =>
                              staff.UserId === formData.RegionalManagerId
                          ) || null
                        }
                        onChange={(event, newValue) =>
                          handleAutocompleteChange(
                            "RegionalManagerId",
                            "UserId",
                            event,
                            newValue
                          )
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.UserId === value.RegionalManagerId
                        }
                        renderOption={(props, option) => (
                          <li {...props}>
                            <div className="customer-dd-border">
                              <div className="row">
                                <div className="col-md-auto">
                                  {" "}
                                  <h6 className="pb-0 mb-0">
                                    {" "}
                                    {option.FirstName} {option.LastName}
                                  </h6>
                                </div>
                                <div className="col-md-auto">
                                  <small>
                                    {"("}
                                    {option.Role}
                                    {")"}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label=""
                            // error={submitClicked && !formData.RegionalManagerId}
                            placeholder="Choose..."
                            className="bg-white"
                          />
                        )}
                      />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Start Date
                              </label>
                              <TextField
                                type="Date"
                                className="form-control"
                                name="LastName"
                                variant="outlined"
                                size="small"
                              />
                            </div>
                          
                          <div className="col-md-4 mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                             Refrence Address
                            </label>
                            <TextField
                              type="text"
                              className="form-control"
                              variant="outlined"
                              size="small"
                              placeholder="Address"
                            />
                          </div>
                          
                          <div className="col-md-4 mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                             Rotation Length
                            </label>
                            <TextField
                              type="text"
                              className="form-control"
                              name="Email"
                              variant="outlined"
                              size="small"
                            />
                          </div>
                         
                            <div className="col-md-4 mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Irr Inspection Freq
                              </label>
                              <TextField
                                type="text"
                                className="form-control"
                                name="DisplayName"
                                variant="outlined"
                                size="small"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                               Labour Hours
                              </label>
                              <TextField
                                type="password"
                                className="form-control"
                                name="Password"
                                variant="outlined"
                                size="small"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Gate Code
                              </label>
                              <TextField
                                type="text"
                                className="form-control"
                                name="gatecode"
                                variant="outlined"
                                size="small"
                                placeholder="code"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                GLandscape Walk Date
                              </label>
                              <TextField
                                type="date"
                                className="form-control"
                                name="LandscapeWalkDate"
                                variant="outlined"
                                size="small"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Board Meeting Date
                              </label>
                              <TextField
                                type="Date"
                                className="form-control"
                                name="meetingdate"
                                variant="outlined"
                                size="small"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Mow Day
                              </label>
                              <TextField
                                type="text"
                                className="form-control"
                                name="FirstName"
                                variant="outlined"
                                size="small"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Type of Mowers
                              </label>
                              <TextField
                                type="text"
                                className="form-control"
                                name="FirstName"
                                variant="outlined"
                                size="small"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Fertilizer # of Bags
                              </label>
                              <TextField
                                type="number"
                                className="form-control"
                                name="FirstName"
                                variant="outlined"
                                size="small"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                # of flats of color
                              </label>
                              <TextField
                                type="number"
                                className="form-control"
                                name="FirstName"
                                variant="outlined"
                                size="small"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Mulch quantity
                              </label>
                              <TextField
                                type="number"
                                className="form-control"
                                name="FirstName"
                                variant="outlined"
                                size="small"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                               Number of Controllers
                              </label>
                              <TextField
                                type="text"
                                className="form-control"
                                name="FirstName"
                                variant="outlined"
                                size="small"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Contract Inclusions
                              </label>
                              <TextField
                                type="text"
                                className="form-control"
                                name="FirstName"
                                variant="outlined"
                                size="small"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Irrigation Hours Included?
                              </label>
                              <TextField
                                type="text"
                                className="form-control"
                                name="FirstName"
                                variant="outlined"
                                size="small"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                QAC included?
                              </label>
                              <TextField
                                type="text"
                                className="form-control"
                                name="FirstName"
                                variant="outlined"
                                size="small"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Overseeding # of bags
                              </label>
                              <TextField
                                type="number"
                                className="form-control"
                                name="FirstName"
                                variant="outlined"
                                size="small"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                PGR 5gal BPS
                              </label>
                              <TextField
                                type="number"
                                className="form-control"
                                name="FirstName"
                                variant="outlined"
                                size="small"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                               Reclaimed Water
                              </label>
                              <TextField
                                type="text"
                                className="form-control"
                                name="FirstName"
                                variant="outlined"
                                size="small"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Dog Bags Included?
                              </label>
                              <TextField
                                type="text"
                                className="form-control"
                                name="FirstName"
                                variant="outlined"
                                size="small"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                               Misc
                              </label>
                              <TextField
                                type="text"
                                className="form-control"
                                name="FirstName"
                                variant="outlined"
                                size="small"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                               Concern
                              </label>
                              <TextField
                                type="text"
                                className="form-control"
                                name="FirstName"
                                variant="outlined"
                                size="small"
                              />
                            </div>
               
                        </div>
                      </div>
                    </div>
                    <EventPopups
                    //   open={openSnackBar}
                    //   setOpen={setOpenSnackBar}
                    //   color={snackBarColor}
                    //   text={snackBarText}
                    />
                    <div className="row">
                      
                      <div className="col-md-9">
                        <BackButton
                        onClick={handleClosePopup}>
                          Back
                        </BackButton>
                        </div>
                        
                     
                      <div className="col-md-3 text-end">
                        <LoaderButton
                        //   loading={disableButton}
                        //   handleSubmit={handleSubmit}
                        >
                          Save
                        </LoaderButton>
                      </div>
                    </div>
                  </div>
            
              </div>
        </div>
      
    </>
    
  )
}

export default JobForm
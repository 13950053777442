import React, { useContext, useEffect, useState } from "react";
import TitleBar from "../TitleBar";
import { GrDocumentPerformance } from "react-icons/gr";
import formatAmount from "../../custom/FormatAmount";
import KpiCard from "./KpiCard";
import ItemCharts from "./ItemCharts";
import useGetApi from "../Hooks/useGetApi";
import EstimateData from "./EstimateData";
import {
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import Monthlies from "./Monthlies";
import SrCard from "./SrCard";
import CostTracking from "./CostTracking";
import { DataContext } from "../../context/AppData";
import { EstimateForm } from "./EstimateForm";
import DataTable from "./DataTable";

const KpisDBoard = () => {
  const { getData } = useGetApi();
  const { loggedInUser } = useContext(DataContext);
  const AgentId = loggedInUser?.userRole == 1 ? 0 : loggedInUser?.userId;
  const [cardData, setcardData] = useState({});
  const [monthReport, setMonthReport] = useState([]);
  const [PLReport, setPLReport] = useState([]);
  const [loading, setLoading] = useState(true);
  const [PLloading, setPlLoading] = useState(false);
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // Months are zero-based, so add 1
  const currentYear = currentDate.getFullYear();
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const years = Array.from(
    { length: currentYear - 2009 },
    (_, index) => currentYear - index
  );
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const getReportData = (month, year) => {
    getData(
      `/KPI/GetAgentEstimateMonthlyReport?AgentId=${AgentId}&Month=${month}&Year=${year}`,
      (data) => {
        setcardData(data);
        setLoading(false);
      },
      (err) => {
        console.log("GetAgentEstimateMonthlyReport errr", err);
        setLoading(false);
      }
    );
  };
  const getmonthReportData = (year) => {
    getData(
      `/KPI/GetMonthlysReport?Year=${year}`,
      (data) => {
        setMonthReport(data);
        setLoading(false);
      },
      (err) => {
        console.log("GetAgentEstimateMonthlyReport errr", err);
        setLoading(false);
      }
    );
  };
  const getPLData = (year) => {
    setPlLoading(true);
    getData(
      `/Report/GetPLReport?Year=${year}`,
      (data) => {
        setPLReport(data);
        setPlLoading(false);
      },
      (err) => {
        console.log("GetAgentEstimateMonthlyReport errr", err);
        setPlLoading(false);
      }
    );
  };
  const handleApiCall = () => {
    setLoading(true);
    getReportData(selectedMonth, selectedYear);
    getmonthReportData(selectedYear);
    getPLData(selectedYear);
    setLoading(false);
  };
  useEffect(() => {
    getReportData(selectedMonth, selectedYear);
    getmonthReportData(selectedYear);
    getPLData(selectedYear);
  }, []);

  if (loading) {
    return (
      <div className="center-loader">
        <CircularProgress style={{ color: "#789a3d" }} />
      </div>
    );
  }

  return (
    <div>
      <TitleBar
        icon={<GrDocumentPerformance size={"20px"} />}
        title={"KPIs Dashboard"}
      ></TitleBar>

      <div className="container-fluid">
        
      <div className="row mb-3">
  {/* Year Dropdown */}
  <div className="col-2">
    <label className="form-label">Year</label>
    <FormControl fullWidth>
      <Select
        size="small"
        name="Year"
        value={selectedYear}
        onChange={(e) => setSelectedYear(e.target.value)}
      >
        {years.map((year) => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </div>

  {/* Month Dropdown */}
  <div className="col-2">
    <label className="form-label">Month</label>
    <FormControl fullWidth>
      <Select
        size="small"
        name="Month"
        value={selectedMonth}
        onChange={(e) => setSelectedMonth(e.target.value)}
      >
        {months.map((month, index) => (
          <MenuItem key={index} value={index + 1}>
            {month}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </div>

  {/* Generate Button */}
  <div className="col-2 d-flex align-items-end">
    <Button
      variant="contained"
      style={{ height: "35px" }}
      onClick={handleApiCall}
    >
      Generate
    </Button>
  </div>
</div>


        <EstimateData data={cardData} />
        <div className="mt-3">
          <SrCard
            data={cardData}
            loggedInUser={loggedInUser}
            cardData={cardData}
          />
        </div>

        <div className="row mt-3">
          <div className="col-md-5 h-100">
            <CostTracking chartData={cardData} />
          </div>
          {loggedInUser?.userRole == 1 && (
            <div className="col-md-7  h-100">
              <Monthlies monthReport={monthReport} />
            </div>
          )}
        </div>
        <div className="row">
          {loggedInUser?.userRole == 1 && loggedInUser?.CompanyId == 1 && (

            <>
            <div className="col-md-12 mt-3">
              <ItemCharts PLReport={PLReport} PLloading={PLloading} />
            </div>
             <div>
             <DataTable data={PLReport} PLloading={PLloading}/>
            </div>
            </>
          )}
        </div>
          <EstimateForm />
        
       
      </div>
    </div>
  );
};

export default KpisDBoard;

import { CircularProgress } from "@mui/material";
import React from "react";

const DataTable = ({ data, PLloading }) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const filteredMonths = data.map((item) => months[item.Month - 1]);

  return (
    <div style={{ overflowX: "auto" }} className="estimatedata-kpi-bg">
      <div className="border-bottom border-black d-md-block border-bottom-md">
        <h6 className="mb-0 p-2" style={{ color: "#2c2c2c" }}>
          Material and Expense Data
        </h6>
      </div>
      {PLloading ? (
       <div className="center-loader">  <CircularProgress style={{ color: "#789a3d" }} /></div>
      ) : (    <table
        style={{
          borderCollapse: "collapse",
          width: "100%",
        }}
      >
        <thead>
          <tr>
            <td style={tableHeaderStyle}>
              <h6 style={{ color: "#2c2c2c" }}>Category</h6>
            </td>
            {filteredMonths.map((month, index) => (
              <td key={index} style={tableHeaderStyle}>
                <h6 style={{ color: "#2c2c2c" }}>{month}</h6>
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
  {renderCategoryRows("Irrigation Data", "Material", "IrrigationMaterial", data, "#C8E6C9")}
  {renderCategoryRows("Plant Data", "Material", "PlantMaterial", data, "#BBDEFB")}
  {renderCategoryRows("Mulch Data", "Material", "Mulch", data, "#FFE0B2")}
  {renderCategoryRows("General Data", "Material", "GeneralMaterial", data, "#E1BEE7")}
</tbody>


      </table>)}
    </div>
  );
};

const renderCategoryRows = (heading,category, key, data,bgColor) => (
  <>
  <tr>
      <td colSpan={data.length + 1} style={{ 
        padding: "8px", 
        textAlign: "center", 
        // fontWeight: "bold", 
        backgroundColor: bgColor, 
        color: "#000000" 
      }}>
        {heading}
      </td>
    </tr>

    <tr>
      <td style={categoryCellStyle}>
        <h6 style={{ color: "#2c2c2c" }}>{category}</h6>
      </td>
      {data.map((item, index) => (
        <td key={index} style={categoryCellStyle}>
          {item[key] ?? "-"}
        </td>
      ))}
    </tr>
    <tr>
      <td style={tableCellStyle}>
        {" "}
        <h6 style={{ color: "#2c2c2c" }}>Expense</h6>
      </td>
      {data.map((item, index) => (
        <td key={index} style={tableCellStyle}>
          {item[`${key}Expense`] ?? "-"}
        </td>
      ))}
    </tr>
    <tr>
  <td style={tableCellStyle}>
    <h6 style={{ color: "#2c2c2c" }}>Profit</h6>
  </td>
  {data.map((item, index) => {
    const profitValue = item[`${key}Profit`];

    // Check if profitValue is a valid number
    if (typeof profitValue === 'number' && !isNaN(profitValue)) {
      return (
        <td key={index} style={tableCellStyle}>
          {profitValue.toFixed(2)}%
        </td>
      );
    } else {
      return (
        <td key={index} style={tableCellStyle}>
          0%
        </td>
      );
    }
  })}
</tr>
  </>
);

const tableHeaderStyle = {
  borderBottom: "1px solid #d3d3d3",
  padding: "10px",
};

const tableCellStyle = {
  border: "1px solid #d3d3d3",
  // borderBottom: "1px solid #d3d3d3",
  padding: "8px",
};

const categoryCellStyle = {
  ...tableCellStyle,
  borderTop: "1px solid #d3d3d3",
 
};

export default DataTable;

import React from "react";
import { GrDocumentPerformance } from "react-icons/gr";
import formatAmount from "../../custom/FormatAmount";
import CheckBoxIcon from "@mui/icons-material/BeenhereOutlined";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import FactCheckIcon from "@mui/icons-material/FactCheckOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import DescriptionIcon from "@mui/icons-material/Description";
import PercentIcon from "@mui/icons-material/Percent";
import { HmacSHA224 } from "crypto-js";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import RequestPageOutlinedIcon from "@mui/icons-material/RequestPageOutlined";
import PendingActionsOutlinedIcon from "@mui/icons-material/PendingActionsOutlined";
import HomeRepairServiceOutlinedIcon from "@mui/icons-material/HomeRepairServiceOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
const SrCard = ({ data, loggedInUser }) => {
  return (
    <div className="row">
      {loggedInUser?.CompanyId == 2 && (
        <>
          {" "}
          <div className="col-md-4">
            <div className="mantainess-kpi-bg ">
              <div className="border-bottom border-black  d-md-block border-bottom-md">
                <h6 className=" mb-0 p-2" style={{ color: "#2c2c2c" }}>
                  {" "}
                  Maintenance
                </h6>
              </div>

              <div className="row ">
                <div className="col-md-12 p-5 py-2">
                  <div className=" ">
                    <div
                      className="d-flex align-items-center justify-content-between"
                      style={{ marginTop: "9px" }}
                    >
                      <div className="d-flex align-items-center">
                        <div
                          className={`icon-box bg-warning-light`}
                          style={{ marginRight: "14px" }}
                        >
                          <PendingActionsOutlinedIcon color={"warning"} />
                        </div>
                        <p style={{ color: "#808080" }} className="font-w500">
                          Jobs Submitted
                        </p>
                      </div>

                      <h6 style={{ color: "#2c2c2c" }}>
                        {formatAmount(
                          data.NoOfMaintenanceEstiamteSubmitted,
                          2,
                          true
                        )}
                      </h6>
                     
                    </div>

                    <div
                      className="d-flex align-items-center justify-content-between"
                      style={{ marginTop: "9px" }}
                    >
                      <div className="d-flex align-items-center">
                        <div className={`icon-box bg-success-light `}>
                          <CheckBoxIcon color={"success"} />
                        </div>
                        <p
                          style={{ color: "#808080", marginLeft: "9px" }}
                          className="font-w500"
                        >
                          Jobs Approved
                        </p>
                      </div>

                      <h6 style={{ color: "#2c2c2c" }}>
                        {formatAmount(
                          data.NoOfMaintenanceEstiamteApproved,
                          2,
                          true
                        )}
                      </h6>
                    </div>

                    <div
                      className="d-flex align-items-center justify-content-between"
                      style={{ marginTop: "9px" }}
                    >
                      <div className="d-flex align-items-center">
                        <div className={`icon-box bg-success-light `}>
                          <AttachMoneyIcon color={"success"} />
                        </div>
                        <p
                          style={{ color: "#808080", marginLeft: "9px" }}
                          className="font-w500"
                        >
                          Amount
                        </p>
                      </div>

                      <h6 style={{ color: "#2c2c2c" }}>
                        <span style={{ fontSize: "16px", color: "#2c2c2c" }}>
                          $
                        </span>
                        {formatAmount(
                          data.AmountMaintenanceEstiamteApproved,
                          2,
                          true
                        )}
                      </h6>
                    </div>

                    <div
                      className="d-flex align-items-center justify-content-between  pb-1"
                      style={{ marginTop: "9px" }}
                    >
                      <div className="d-flex align-items-center">
                        <div className={`icon-box bg-warning-light `}>
                          <PercentIcon color={"warning"} />
                        </div>
                        <p
                          style={{ color: "#808080", marginLeft: "9px" }}
                          className="font-w500"
                        >
                          Close
                        </p>
                      </div>

                      <h6 style={{ color: "#2c2c2c" }}>
                        {`${formatAmount(
                          data.CloseMaintenanceEstiamtePercentage,
                          2,
                          true
                        )}%`}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          <div className="col-md-4">
            <div className="non-mantainess-kpi-bg ">
              <div className="border-bottom border-black  d-md-block border-bottom-md">
                {/* <h4 style={{ color: "#2c2c2c",marginLeft:"18px" }} className="pt-2">
                  Non Maintainess
                </h4> */}
                <h6 className="mb-0 p-2 " style={{ color: "#2c2c2c" }}>
                  {" "}
                  Non Maintenance
                </h6>
              </div>
              <div className="row ">
                <div className="col-md-12 p-5 py-2">
                  <div className=" ">
                    <div
                      className="d-flex align-items-center justify-content-between"
                      style={{ marginTop: "9px" }}
                    >
                      <div className="d-flex align-items-center">
                        <div className={`icon-box bg-warning-light `}>
                          <PendingActionsOutlinedIcon color={"warning"} />
                        </div>
                        <p
                          style={{ color: "#808080", marginLeft: "9px" }}
                          className="font-w500"
                        >
                          Jobs Submitted
                        </p>
                      </div>

                      <h6 style={{ color: "#2c2c2c" }}>
                        {/* {data.NoOfNonMaintenanceEstiamteSubmitted} */}
                        {formatAmount(
                          data.NoOfNonMaintenanceEstiamteSubmitted,
                          2,
                          true
                        )}
                      </h6>
                    </div>

                    <div
                      className="d-flex align-items-center justify-content-between"
                      style={{ marginTop: "9px" }}
                    >
                      <div className="d-flex align-items-center">
                        <div className={`icon-box bg-success-light `}>
                          <CheckBoxIcon color={"success"} />
                        </div>
                        <p
                          style={{ color: "#808080", marginLeft: "9px" }}
                          className="font-w500"
                        >
                          Jobs Approved
                        </p>
                      </div>

                      <h6 style={{ color: "#2c2c2c" }}>
                        {formatAmount(
                          data.NoOfNonMaintenanceEstiamteApproved,
                          2,
                          true
                        )}
                      </h6>
                    </div>

                    <div
                      className="d-flex align-items-center justify-content-between"
                      style={{ marginTop: "9px" }}
                    >
                      <div className="d-flex align-items-center">
                        <div
                          className={`icon-box bg-success-light`}
                          style={{ marginRight: "14px" }}
                        >
                          <AttachMoneyIcon color={"success"} />
                        </div>
                        <p style={{ color: "#808080" }} className="font-w500">
                          Amount
                        </p>
                      </div>

                      <h6 style={{ color: "#2c2c2c" }}>
                        <span style={{ fontSize: "16px", color: "#2c2c2c" }}>
                          $
                        </span>
                        {formatAmount(
                          data.AmountNonMaintenanceEstiamteApproved,
                          2,
                          true
                        )}
                      </h6>
                    </div>

                    <div
                      className="d-flex align-items-center justify-content-between pb-1"
                      style={{ marginTop: "9px" }}
                    >
                      <div className="d-flex align-items-center ">
                        <div
                          className={`icon-box bg-warning-light`}
                          style={{ marginRight: "14px" }}
                        >
                          <PercentIcon color={"warning"} />
                        </div>{" "}
                        <p style={{ color: "#808080" }} className="font-w500">
                          Close
                        </p>
                      </div>

                      <h6 style={{ color: "#2c2c2c" }}>
                        {`${formatAmount(
                          data.CloseNonMaintenanceEstiamtePercentage,
                          2,
                          true
                        )}%`}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="col-md-4">
        <div className="sr-kpi-bg  h-100">
          <div className="border-bottom border-black  d-md-block border-bottom-md">
            {/* <h4 style={{ color: "#2c2c2c",marginLeft:"18px" }} className="pt-2">
              Service Requests
            </h4> */}
            <h6 className="mb-0 p-2 " style={{ color: "#2c2c2c" }}>
              Service Requests
            </h6>
          </div>
          <div className="row">
            <div className="col-md-12 p-5 py-2">
              <div
                className="d-flex align-items-center justify-content-between  pb-1"
                style={{ marginTop: "9px" }}
              >
                <div className="d-flex align-items-center">
                  <div className={`icon-box bg-warning-light `}>
                    <HomeRepairServiceOutlinedIcon color={"warning"} />
                  </div>
                  <p
                    style={{ color: "#808080", marginLeft: "9px" }}
                    className="font-w500"
                  >
                    # of Service Requests
                  </p>
                </div>

                <h6 style={{ color: "#2c2c2c" }}>
                  {formatAmount(data.NoOfServiceRequest, 2, true)}
                </h6>
              </div>

              <div
                className="d-flex align-items-center justify-content-between  pb-1"
                style={{ marginTop: "9px" }}
              >
                <div className="d-flex align-items-center">
                  <div className={`icon-box bg-info-light `}>
                    <CalendarTodayOutlinedIcon color={"info"} />
                  </div>
                  <p
                    style={{ color: "#808080", marginLeft: "9px" }}
                    className="font-w500"
                  >
                    Avg Days to closed
                  </p>
                </div>

                <h6 style={{ color: "#2c2c2c" }}>
                  {formatAmount(data.AvgTimeToCloseSR, 2, true)}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SrCard;

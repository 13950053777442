import useGetApi from "../Hooks/useGetApi";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import {
  Autocomplete,
  TextField,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { get } from "jquery";
import { baseUrl } from "../../apiConfig";
import LoaderButton from "../Reusable/LoaderButton";
import EventPopups from "../Reusable/EventPopups";
export const EstimateForm = () => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // Months are zero-based, so add 1
  const currentYear = currentDate.getFullYear();
const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("");
  const [snackBarText, setSnackBarText] = useState("");
  const years = Array.from(
    { length: currentYear - 2009 },
    (_, index) => currentYear - index
  );
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectManager, setSelectManager] = useState("");
  const [loading, setLoading] = useState(false);
  const [inputField, setInputField] = useState("");
  const [extraField, setExtraField] = useState("");
  const { getData } = useGetApi();
  const [formValues, setFormValues] = useState({});
  const [staffData, setStaffData] = useState([]);
  const token = Cookies.get("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  

  const [formData, setFormData] = useState({});
  console.log("🚀 ~ formData:", formData)


  const totalLabelSale =
    (Number(formData.MonthlySale) || 0) + Number(formValues?.ExtrasValue);
  // Calcualate All getPercentage %
  const getPercentage = totalLabelSale
    ? ((Number(formData.LaborerRate || 0) +
        Number(formData.ForemenRate || 0) +
        Number(formData.IrrigatorRate || 0)) /
        totalLabelSale) *
      100
    : 0; // Avoid division by zero
  // Calcualate irrigatorPer %
  const irrigatorPer = formValues?.IrrigatorValue
    ? (Number(formData?.IrrigatorRate || 0) /
        Number(formValues.IrrigatorValue)) *
      100
    : 0; // Avoid division by zero
  // Calcualate sprayTechPer %
  const sprayTechPer = formValues?.SprayTechValue
    ? (Number(formData?.SpraytechRate || 0) /
        Number(formValues.SprayTechValue)) *
      100
    : 0; // Prevents division by zero

  // Calcualate Thired Column
  const laberThired = Number(inputField) + Number(extraField);
  // Calcualate sales
  const totalSales =
    (Number(formData.MonthlySale) || 0) +
    (Number(formValues?.ExtrasValue) || 0) +
    (Number(formValues?.IrrigatorValue) || 0) +
    (Number(formValues?.SprayTechValue) || 0);
  //Calculate ROWs
  const totalrate =
    (Number(formData?.LaborerRate) || 0) +
    (Number(formData?.ForemenRate) || 0) +
    (Number(formData?.IrrigatorRate) || 0) +
    (Number(formData?.SpraytechRate) || 0);

 
  
  const firstAmmountCal = laberThired
      ? (((Number(formData.LaborerPayroleAmount) || 0) +
          (Number(formData.ForemenPayroleAmount) || 0) +
          (Number(formData.SpraytechPayroleAmount) || 0)) /
          laberThired) *
        100
      : 0;
  
  const totalper = (totalrate / totalSales) * 100;

  const SubmitHandler = async () => {
    setLoading(true)
    try {
      const { data } = await axios.post(
        `${baseUrl}/api/KPI/AddAgentLaborerReport`,
        formData, 
        {
          headers,
        }
      );
      setOpenSnackBar(true);
      setSnackBarColor("success");
      setSnackBarText(data.Message);
      setLoading(false)
    } catch (error) {
      setLoading(false)
   
    }
  };

  const getKpiFormData = (month, year, manager) => {
    getData(
      `/KPI/GetLaborerReportValues?Year=${year}&Month=${month}&AgentId=${manager}`,

      (data) => {
        setFormValues(data);
      },
      (err) => {
        console.log("🚀 ~ getKpiFormData ~ err:", err);
      }
    );
  };
  const getKpireportValue = (month, year, manager) => {
    getData(
      `/KPI/GetAgentLaborerReport?AgentId=${manager}&Year=${year}&Month=${month}`,
   
      (data) => {
        setFormData(data);
      },
      (err) => {
      }
    );
  };

  const fetchStaffList = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/Staff/GetStaffList`, {
        headers,
      });
      setStaffData(response.data);
    } catch (error) {
      console.log("error getting staff list", error);
    }
  };
  useEffect(() => {
    fetchStaffList();
  }, []);
  useEffect(() => {
    if (formValues) {
      setFormData((prevData) => ({
        AgentLaborReportId:formData.AgentLaborReportId?formData.AgentLaborReportId:0,
        ExtrasSale: formValues?.ExtrasValue ?? 0,
        IrrigatorSale: formValues?.IrrigatorValue ?? 0,
        SpraytechSale: formValues?.SprayTechValue ?? 0,
        LaborerSale: totalLabelSale, // Use previous state here
        UserId: selectManager,
        Month: selectedMonth,
        Year: selectedYear,
        IrrigatorRate:formData?.IrrigatorRate,
        ForemenRate:formData?.ForemenRate,
        LaborerRate:formData?.LaborerRate,
        SpraytechRate:formData?.SpraytechRate,
        IrrigatorPayroleAmount:formData?.IrrigatorPayroleAmount,
        LaborerPayroleAmount:formData?.LaborerPayroleAmount,
        ForemenPayroleAmount:formData?.ForemenPayroleAmount,
        SpraytechPayroleAmount:formData?.SpraytechPayroleAmount
      }));
    }
  }, [formValues]);
  const handleAutocompleteChange = (
    fieldName,
    valueProperty,
    event,
    newValue
  ) => {
    const simulatedEvent = {
      target: {
        name: fieldName,
        value: newValue ? newValue[valueProperty] : "",
      },
    };
    handleInputChange(simulatedEvent);
  };
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSelectManager(value);
 
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  useEffect(() => {
    if (selectedMonth || selectedYear || selectManager) {
      getKpiFormData(selectedMonth, selectedYear, selectManager);
      getKpireportValue(selectedMonth, selectedYear, selectManager);
    }
  }, [selectedMonth, selectedYear, selectManager]);

  return (
    <div className="row d-flex mt-3" style={{ width: "100%", marginLeft: "0px" }}>
      <EventPopups
              open={openSnackBar}
              setOpen={setOpenSnackBar}
              color={snackBarColor}
              text={snackBarText}
            />
      <div className="col-md-3 card kpi-form " style={{ width: "100%", maxWidth: "25.2%" }}>
        <div className="row p-1 pt-3 pb-3">
          <div className="col-md-12">
            <label className="form-label pb-1" style={{color:"#2c2c2c"}}>Regional Manager</label>
            
            <Autocomplete
              id="staff-autocomplete"
              size="small"
              options={staffData.filter(
                (staff) =>
                  staff.Role === "Regional Manager" ||
                  staff.UserId === 1593 ||
                  staff.UserId === 3252 ||
                  staff.UserId === 6146 ||
                  staff.UserId === 9517
              )}
              getOptionLabel={(option) =>
                option.FirstName + " " + option.LastName || ""
              }
              value={
                staffData.find((staff) => staff.UserId === selectManager) ||
                null
              }
              onChange={(event, newValue) =>
                handleAutocompleteChange(
                  "selectManager",
                  "UserId",
                  event,
                  newValue
                )
              }
              isOptionEqualToValue={(option, value) =>
                option.UserId === value.selectManager
              }
              renderOption={(props, option) => (
                <li {...props}>
                  <div className="customer-dd-border">
                    <div className="row">
                      <div className="col-md-auto">
                        {" "}
                        <h6 className="pb-0 mb-0">
                          {" "}
                          {option.FirstName} {option.LastName}
                        </h6>
                      </div>
                      <div className="col-md-auto">
                        <small>
                          {"("}
                          {option.Role}
                          {")"}
                        </small>
                      </div>
                    </div>
                  </div>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=""
                  // error={submitClicked && !formData.RegionalManagerId}
                  placeholder="Choose..."
                  className="bg-white"
                />
              )}
            />
          </div>
          <div className="col-md-6">
            <label className="form-label mt-2  pb-1" style={{color:"#2c2c2c"}}>Year</label>
            <FormControl fullWidth>
              <Select
                size="small"
                name="Year"
                value={selectedYear}
                onChange={(e) => {
                  setSelectedYear(e.target.value);
                }}
              >
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-md-6">
            <label className="form-label mt-2  pb-1" style={{color:"#2c2c2c"}}>Month</label>
            <FormControl fullWidth>
              <Select
                size="small"
                name="Month"
                value={selectedMonth}
                onChange={(e) => {
                  setSelectedMonth(e.target.value);
                }}
              >
                {months.map((month, index) => (
                  <MenuItem key={index} value={index + 1}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div
        className="col-md-9 card kpi-form"
        style={{ width: "100%", maxWidth: "73%", marginLeft: "10px" }}
      >
        <div className="border-bottom border-black">
          <h6 className="mb-0 p-2 " style={{color:"#2c2c2c"}}>Payroll Details</h6>
        </div>
        <div className="card-body">
          <div
            style={{
              overflowX: "auto",
              maxWidth: "100%",
              minWidth: "50%",
            }}
            className="table-responsive"
          >
            <table className="table">
              <tbody>
                <tr>
                  <th></th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Rates</td>
                  <td>Sales</td>
                  <td>Percent</td>
                  <td># of Guys</td>
                </tr>
                {/* <td>Monthly</td> */}
                <tr>
                  <td>Monthly</td>
                  <th></th>

                  <td>
                    {" "}
                    <TextField
                      value={inputField}
                      onChange={(e) => setInputField(e.target.value)}
                      name="inputField"
                      type="number"
                      size="small"
                      style={{ width: "85%" }}
                      InputProps={{ style: { height: "26px" } }}
                    />
                  </td>

                  <td></td>
                  <th></th>
                  <td>
                    <TextField
                      value={formData.MonthlySale || ""}
                      onChange={handleChange}
                      name="MonthlySale"
                      type="number"
                      size="small"
                      style={{ width: "85%" }}
                      InputProps={{ style: { height: "26px" } }}
                    />
                  </td>
                  <td></td>
                  <td>
                    {/* <TextField
                        value={formData.monthlyValuesthird}
                        onChange={handleChange}
                        name="monthlyValuesthird"
                        type="number"
                        size="small"
                        style={{ width: "85%" }}
                        InputProps={{
                          style: { height: "26px" },
                          inputProps: {
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          },
                        }}
                      /> */}
                  </td>
                </tr>
                {/* <td>Extras</td> */}
                <tr>
                  <td>Extras</td>

                  <td></td>
                  <td>
                    {" "}
                    <TextField
                      value={extraField}
                      onChange={(e) => setExtraField(e.target.value)}
                      name="extraField"
                      type="number"
                      size="small"
                      style={{ width: "85%" }}
                      InputProps={{ style: { height: "26px" } }}
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td className="text-center">
                    <label>{`${Number(formValues?.ExtrasValue || 0).toFixed(
                      2
                    )}`}</label>

                    {/* <TextField
                        disabled
                        placeholder="4"
                        name="extrasGuys"
                        type="number"
                        size="small"
                        value={formValues?.ExtrasValue}
                        style={{ width: "85%" }}
                        InputProps={{ style: { height: "26px" } }}
                      /> */}
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                {/* <td>Laborer</td> */}
                <tr>
                  <td>Laborer</td>

                  <td>
                    {" "}
                    <TextField
                      value={formData.LaborerPayroleAmount}
                      onChange={handleChange}
                      name="LaborerPayroleAmount"
                      type="number"
                      size="small"
                      style={{ width: "85%" }}
                      InputProps={{ style: { height: "26px" } }}
                    />
                  </td>
                  <td className="text-center">
                    <label>{`${laberThired}`}</label>{" "}
                    {/* <TextField
                        disabled
                        value={laberThired}
                        name="laberThired"
                        type="number"
                        size="small"
                        style={{ width: "85%" }}
                        InputProps={{ style: { height: "26px" } }}
                      /> */}
                  </td>
                  <td className="text-center">
                    <label>
                    <label>{isNaN(firstAmmountCal) ? "0%" : `${firstAmmountCal.toFixed(2)}%`}</label>

                    </label>
                    {/* <TextField
                        placeholder="7"
                        name="extrasGuys"
                        type="number"
                        size="small"
                        style={{ width: "85%" }}
                        InputProps={{ style: { height: "26px" } }}
                      /> */}
                  </td>
                  <td>
                    {" "}
                    <TextField
                      value={formData.LaborerRate || ""}
                      onChange={handleChange}
                      name="LaborerRate"
                      type="number"
                      size="small"
                      style={{ width: "85%" }}
                      InputProps={{ style: { height: "26px" } }}
                    />
                  </td>
                  <td className="text-center">
                    {" "}
                    {/* getSumValues(formData?.LaborerSale) */}
                    <label>
                      {isNaN(totalLabelSale)
                        ? "0.00"
                        : totalLabelSale.toFixed(2)}
                    </label>
                    {/* <TextField
                        disabled
                        value={getSumValues(formData?.monthlyValues)}
                        onChange={() => {}}
                        //  placeholder="9"
                        name="monthlyValues"
                        type="number"
                        size="small"
                        style={{ width: "85%" }}
                        InputProps={{ style: { height: "26px" } }}
                      /> */}
                  </td>
                  <td className="text-center">
                    {" "}
                    <label>
                      {isNaN(getPercentage)
                        ? "0%"
                        : `${getPercentage.toFixed(2)}%`}
                    </label>
                    {/* <TextField
                        disabled
                        value={`${getPercentage.toFixed(2)}`}
                        placeholder="10"
                        name="extrasGuys"
                        type="number"
                        size="small"
                        style={{ width: "85%" }}
                        InputProps={{ style: { height: "26px" } }}
                      /> */}
                  </td>
                  <td>
                    {" "}
                    <TextField
                      value={formData.NoOfLaborer}
                      onChange={handleChange}
                      name="NoOfLaborer"
                      type="number"
                      size="small"
                      style={{ width: "60%" }}
                      InputProps={{
                        style: { height: "26px" },
                        inputProps: { style: { appearance: "textfield" } },
                      }}
                    />
                  </td>
                </tr>
                {/* <td>Foremen</td> */}
                <tr>
                  <td>Foremen</td>

                  <td>
                    {" "}
                    <TextField
                      value={formData.ForemenPayroleAmount}
                      onChange={handleChange}
                      name="ForemenPayroleAmount"
                      type="number"
                      size="small"
                      style={{ width: "85%" }}
                      InputProps={{ style: { height: "26px" } }}
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    {" "}
                    <TextField
                      value={formData.ForemenRate || ""}
                      onChange={handleChange}
                      name="ForemenRate"
                      type="number"
                      size="small"
                      style={{ width: "85%" }}
                      InputProps={{ style: { height: "26px" } }}
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    {" "}
                    <TextField
                      value={formData.NoOfForemen}
                      onChange={handleChange}
                      name="NoOfForemen"
                      type="number"
                      size="small"
                      style={{ width: "60%" }}
                      InputProps={{ style: { height: "26px" } }}
                    />
                  </td>
                </tr>
                {/* <td>Irrigator</td> */}
                <tr>
                  <td>Irrigator</td>

                  <td>
                    {" "}
                    <TextField
                      value={formData.IrrigatorPayroleAmount}
                      name="IrrigatorPayroleAmount"
                      onChange={handleChange}
                      type="number"
                      size="small"
                      style={{ width: "85%" }}
                      InputProps={{ style: { height: "26px" } }}
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    {" "}
                    <TextField
                      value={formData.IrrigatorRate || ""}
                      onChange={handleChange}
                      name="IrrigatorRate"
                      type="number"
                      size="small"
                      style={{ width: "85%" }}
                      InputProps={{ style: { height: "26px" } }}
                    />
                  </td>
                  <td className="text-center">
                    {" "}
                    <label>{`${Number(formValues?.IrrigatorValue || 0).toFixed(
                      2
                    )}`}</label>
                    {/* <label>{`${formValues?.IrrigatorValue}`}</label> */}
                    {/* <TextField
                        disabled
                        value={formValues?.IrrigatorValue}
                        name="IrrigatorValue"
                        type="number"
                        size="small"
                        style={{ width: "85%" }}
                        InputProps={{ style: { height: "26px" } }}
                      /> */}
                  </td>

                  <td className="text-center">
                    {" "}
                    <label className="text-center">
                      {isNaN(irrigatorPer)
                        ? "0%"
                        : `${irrigatorPer.toFixed(2)}%`}
                    </label>
                    {/* <TextField
                        value={irrigatorPer.toFixed(2)}
                        disabled
                        placeholder="19"
                        name="irrigatorPer"
                        type="number"
                        size="small"
                        style={{ width: "85%" }}
                        InputProps={{ style: { height: "26px" } }}
                      /> */}
                  </td>
                  <td>
                    {" "}
                    <TextField
                      value={formData.NoOfIrrigator}
                      onChange={handleChange}
                      name="NoOfIrrigator"
                      type="number"
                      size="small"
                      style={{ width: "60%" }}
                      InputProps={{ style: { height: "26px" } }}
                    />
                  </td>
                </tr>
                {/* <td>Spray tech</td> */}
                <tr>
                  <td>Spray tech</td>

                  <td>
                    <TextField
                      value={formData.SpraytechPayroleAmount}
                      onChange={handleChange}
                      name="SpraytechPayroleAmount"
                      type="number"
                      size="small"
                      style={{ width: "85%" }}
                      InputProps={{ style: { height: "26px" } }}
                    />
                  </td>
                  <td></td>
                  <td> </td>
                  <td>
                    {" "}
                    <TextField
                      value={formData.SpraytechRate || ""}
                      onChange={handleChange}
                      name="SpraytechRate"
                      type="number"
                      size="small"
                      style={{ width: "85%" }}
                      InputProps={{ style: { height: "26px" } }}
                    />
                  </td>
                  <td className="text-center">
                    {" "}
                    <label>{`${Number(formValues?.SprayTechValue || 0).toFixed(
                      2
                    )}`}</label>
                    {/* <label>{`${formValues?.SprayTechValue}`}</label> */}
                    {/* <TextField
                        disabled
                        value={formValues?.SprayTechValue}
                        name="SprayTechValue"
                        type="number"
                        size="small"
                        style={{ width: "85%" }}
                        InputProps={{ style: { height: "26px" } }}
                      /> */}
                  </td>
                  <td className="text-center">
                    {" "}
                    <label>
                      {isNaN(sprayTechPer)
                        ? "0%"
                        : `${sprayTechPer.toFixed(2)}%`}
                    </label>
                    {/* <TextField
                        value={sprayTechPer.toFixed(2)}
                        disabled
                        placeholder="23"
                        name="sprayTechPer"
                        type="number"
                        size="small"
                        style={{ width: "85%" }}
                        InputProps={{ style: { height: "26px" } }}
                      /> */}
                  </td>
                  <td>
                    {" "}
                    <TextField
                      value={formData.NoOfSpraytech}
                      onChange={handleChange}
                      name="NoOfSpraytech"
                      type="number"
                      size="small"
                      style={{ width: "60%" }}
                      InputProps={{ style: { height: "26px" } }}
                    />
                  </td>
                </tr>
                {/* <td>Totals</td> */}
                <tr>
                  <td>Totals</td>

                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="text-center">
                    {" "}
                    <label>{isNaN(totalrate) ? 0 : totalrate}</label>
                    {/* <TextField
                        value={rateCalculate}
                        disabled
                        name="totalRates"
                        placeholder="4"
                        type="number"
                        size="small"
                        style={{ width: "85%" }}
                        InputProps={{ style: { height: "26px" } }}
                      /> */}
                  </td>
                  <td className="text-center">
                    {/* <label>{`${totalSales}`}</label> */}
                    <label>
                      {isNaN(totalSales)
                        ? "0.00"
                        : Number(totalSales).toFixed(2)}
                    </label>

                    {/* <TextField
                        value={totalSales}
                        disabled
                        name="totalSales"
                        type="number"
                        size="small"
                        style={{ width: "85%" }}
                        InputProps={{ style: { height: "26px" } }}
                      /> */}
                  </td>
                  <td>
                    {/* <TextField
                        value={totalper.toFixed(2)}
                        disabled
                        placeholder="27"
                        name="extrasGuys"
                        type="number"
                        size="small"
                        style={{ width: "85%" }}
                        InputProps={{ style: { height: "26px" } }}
                      /> */}
                    {/* <label>{isNaN(totalSales) ? 1 : totalSales}</label> */}
                    <label className="text-center">{`${Number(
                      totalper || 0
                    ).toFixed(2)}%`}</label>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex justify-content-end">
              {/* <button class="btn btn-primary">Click</button> */}
              <LoaderButton
                loading={loading}
                handleSubmit={SubmitHandler}
              >
                Save
              </LoaderButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
